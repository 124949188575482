<template>
  <v-app>
    <AppBar />

    <v-main>
      <slot v-if="!isLoading"></slot>

      <v-dialog v-else :value="isLoading" hide-overlay persistent width="500">
        <v-card color="primary">
          <v-card-title>Please stand by</v-card-title>
          <v-card-subtitle>Fetching data</v-card-subtitle>
          <v-card-text>
            <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-main>

    <AppFooter with-version />
  </v-app>
</template>

<script>
import { mapActions } from 'vuex';
import AppBar from '@/components/AppBar';
import AppFooter from '@/components/AppFooter';

export default {
  name: 'DefaultLayout',
  components: { AppBar, AppFooter },
  data() {
    return {
      loaded: false,
    };
  },
  computed: {
    isLoading() {
      return !this.loaded;
    },
  },
  created() {
    this.loaded = false;

    Promise.all([this.fetchProjects()])
      .then(() => {
        setTimeout(() => {
          this.loaded = true;
        }, 800);
      })
      .catch(() => {
        this.showAlert({
          message: 'Error occurred while fetching data',
        });
      });
  },
  methods: {
    ...mapActions({
      fetchProjects: 'projects/fetchProjects',
      showAlert: 'alert/show',
    }),
    goToDashboard() {
      if ('dashboard' === this.$route.name) {
        return;
      }

      this.$router.push({ name: 'dashboard' });
    },
  },
};
</script>

<style lang="scss" scoped></style>
