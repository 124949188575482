import Vue from 'vue';
import VueRouter from 'vue-router';
import NProgress from 'nprogress';
import store from '@/store';
import DefaultLayout from '@/layouts/DefaultLayout';
import LoginLayout from '@/layouts/LoginLayout';
import Dashboard from '@/views/Dashboard';
import Translations from '@/views/Translations';
import Login from '@/views/Login';
import { authentication } from '@/plugins/firebase';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'dashboard',
    component: Dashboard,
    meta: {
      layout: DefaultLayout,
      requiresAuth: true,
    },
  },
  {
    path: '/translations/:project/:subproject?',
    name: 'translations',
    component: Translations,
    meta: {
      layout: DefaultLayout,
      requiresAuth: true,
    },
  },
  {
    path: '/projects/settings',
    name: 'projects-settings',
    component: () => import(/* webpackChunkName: "management__projects-settings" */ '@/views/ProjectsSettings'),
    meta: {
      layout: DefaultLayout,
      requiresAuth: true,
    },
  },
  {
    path: '/users/settings',
    name: 'users-settings',
    component: () => import(/* webpackChunkName: "management__users-settings" */ '@/views/UsersSettings'),
    meta: {
      layout: DefaultLayout,
      requiresAuth: true,
    },
  },
  {
    path: '/reports/logs',
    name: 'reports-logs',
    component: () => import(/* webpackChunkName: "reports__logs" */ '@/views/LogsReport'),
    meta: {
      layout: DefaultLayout,
      requiresAuth: true,
    },
  },
  // {
  //   path: '/migration',
  //   name: 'migration',
  //   component: () => import(/* webpackChunkName: "migration" */ '@/views/Migration'),
  //   meta: {
  //     layout: DefaultLayout,
  //     requiresAuth: true,
  //   },
  // },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      layout: LoginLayout,
      requiresAuth: false,
    },
  },
  {
    path: '/*',
    redirect: { name: 'dashboard' },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  NProgress.start();

  const requiresAuth = to.matched.some((x) => x.meta.requiresAuth);

  if (authentication.currentUser) {
    store.dispatch('user/fetchUser', authentication.currentUser).then(() => {
      next();
    });
  } else {
    if (requiresAuth) {
      store.dispatch('user/logout').then(() => {
        next();
      });
    } else {
      next();
    }
  }
});

router.afterEach(() => {
  NProgress.done();
});

export default router;
