<template>
  <component :is="$route.meta.layout || 'div'">
    <AppAlert />
    <router-view :key="$route.fullPath" />
  </component>
</template>

<script>
import AppAlert from '@/components/AppAlert';

export default {
  name: 'App',
  components: { AppAlert },
};
</script>

<style lang="scss"></style>
