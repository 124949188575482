import store from '@/store';
import { db } from '@/plugins/firebase';

class Logger {
  constructor() {
    this.dbRef = 'logs';
  }

  static getCurrentRef(prefix, type) {
    return `${prefix}/${type}`;
  }
}

Logger.prototype.logRecordChange = async function (payload) {
  return db.ref(Logger.getCurrentRef(this.dbRef, 'translations')).push(
    Object.assign({}, payload, {
      author: store.getters['user/email'],
      date: `${new Date()}`,
    }),
  );
};

Logger.prototype.logUserEvent = async function (payload) {
  return db.ref(Logger.getCurrentRef(this.dbRef, 'users')).push(
    Object.assign({}, payload, {
      author: store.getters['user/email'],
      date: `${new Date()}`,
    }),
  );
};

export default new Logger();
