<template>
  <v-container class="fill-height py-5">
    <v-card v-if="isEditor" class="pa-5" width="100%" elevation="5" rounded="lg">
      <v-subheader class="headline font-weight-bold">Signi translations</v-subheader>

      <v-list v-for="project in projects" :key="project.key" class="px-5" two-line>
        <v-list-item link>
          <v-list-item-avatar @click="goToRoute({ name: 'translations', params: { project: project.code } })">
            <v-img :src="projectLogo" contain>
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-list-item-avatar>

          <v-list-item-content @click="goToRoute({ name: 'translations', params: { project: project.code } })">
            <v-list-item-title v-html="project.title"></v-list-item-title>
            <v-list-item-subtitle v-html="'Signi default translations'"></v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-action v-if="isDeveloper && project.haveExport">
            <v-btn color="primary" icon @click="exportTranslations(project.code)">
              <v-icon>mdi-download</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
        <v-divider inset></v-divider>
      </v-list>
    </v-card>

    <v-card v-if="canShowOnPremises" class="pa-5 mt-5" width="100%" elevation="5" rounded="lg">
      <v-subheader class="headline font-weight-bold">On-premise translations</v-subheader>

      <v-list v-for="project in projects" :key="project.key" class="px-5" two-line>
        <template v-if="hasProjectSubprojects(project)">
          <v-subheader class="subtitle-1 font-weight-bold">{{ project.title }}</v-subheader>

          <v-divider></v-divider>

          <div v-for="subproject in getUserSubprojectsByProject(project)" :key="subproject.key">
            <v-list-item
              link
              @click="
                goToRoute({ name: 'translations', params: { project: project.code, subproject: subproject.key } })
              "
            >
              <template v-if="true"></template>
              <v-list-item-avatar>
                <v-img :src="projectLogo" contain>
                  <template v-slot:placeholder>
                    <v-row class="fill-height ma-0" align="center" justify="center">
                      <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title v-html="subproject.title"></v-list-item-title>
                <v-list-item-subtitle v-html="'Signi default translations'"></v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider inset></v-divider>
          </div>
        </template>
      </v-list>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import { downloadExportFile } from '@/services/exportService';
import { storage } from '@/plugins/firebase';

export default {
  name: 'Dashboard',
  data() {
    return {
      defaultSigniProjectLogo: null,
    };
  },
  computed: {
    ...mapGetters({
      isDeveloper: 'user/isDeveloper',
      isEditor: 'user/isEditor',
      isManager: 'user/isManager',
      isGuest: 'user/isGuest',
      projects: 'projects/list',
      userSubprojects: 'user/subprojects',
    }),
    canShowOnPremises() {
      return this.isManager || (this.isGuest && this.userSubprojects?.length > 0);
    },
    projectLogo() {
      return this.defaultSigniProjectLogo || require('@/assets/signi_logo.png');
    },
  },
  created() {
    storage
      .ref('default/signi_logo.png')
      .getDownloadURL()
      .then((url) => {
        this.defaultSigniProjectLogo = url;
      });
  },
  methods: {
    hasProjectSubprojects(project) {
      return project?.subprojects?.length;
    },
    exportTranslations(projectCode) {
      downloadExportFile({ projectCode });
    },
    getUserSubprojectsByProject(project) {
      if (this.isManager) {
        return project?.subprojects;
      }

      return project?.subprojects?.filter((subproject) => {
        return this.userSubprojects.includes(subproject.key);
      });
    },
    goToRoute(route) {
      if (
        (route.name === this.$route.name && 'translations' !== route.name) ||
        ('translations' === route.name && route?.params?.project === this.$route?.params?.project)
      ) {
        return;
      }

      this.$router.push(route);
    },
  },
};
</script>

<style lang="scss" scoped>
.v-card {
  transition: opacity 0.4s ease-in-out;
}

.v-card:not(.on-hover) {
  opacity: 0.9;
}
</style>
