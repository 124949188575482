<template>
  <v-app>
    <v-main>
      <v-container class="fill-height">
        <slot></slot>
      </v-container>
    </v-main>
    <AppFooter />
  </v-app>
</template>

<script>
import AppFooter from '@/components/AppFooter';

export default {
  name: 'LoginLayout',
  components: { AppFooter },
};
</script>

<style lang="scss" scoped></style>
