<template>
  <v-footer>
    <v-row align="center" justify="space-between" no-gutters>
      <v-col cols="2" class="text-center"> </v-col>
      <v-col class="text-center">
        <span class="subtitle-2 font-weight-bold" v-html="footerText"></span>
      </v-col>
      <v-col cols="2" class="text-right">
        <strong v-if="withVersion" class="body-2">{{ version }}</strong>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
export default {
  name: 'AppFooter',
  props: {
    withVersion: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    currentYear() {
      return new Date().getFullYear();
    },
    footerText() {
      return `${this.currentYear} — <strong>Created by d283k</strong>`;
    },
    version() {
      return require('@/../package.json').version;
    },
  },
};
</script>

<style lang="scss" scoped></style>
