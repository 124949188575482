<template>
  <v-card class="pa-5" elevation="0">
    <v-data-table
      :headers="headers"
      :items="translationsRows"
      :search="search"
      :loading="isLoading"
      :items-per-page="15"
    >
      <template v-slot:top>
        <v-toolbar flat dense>
          <v-toolbar-title>Search</v-toolbar-title>

          <v-divider class="mx-4" inset vertical></v-divider>

          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search row"
            single-line
            hide-details
          ></v-text-field>

          <v-divider v-if="'default' === subprojectCode" class="mx-4" inset vertical></v-divider>

          <v-dialog v-model="dialog">
            <template v-if="'default' === subprojectCode" v-slot:activator="{ on, attrs }">
              <v-btn color="primary" class="mb-2" dark v-bind="attrs" v-on="on">New Record</v-btn>
            </template>

            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        v-model="editedItem.key"
                        label="Development key"
                        outlined
                        :disabled="-1 < editedIndex"
                        :error="keyErrorOccurred"
                        hide-details
                        @change="keyErrorOccurred = false"
                      ></v-text-field>
                    </v-col>

                    <template v-for="item in sortedProjectHeaderColumns">
                      <v-col v-if="'key' !== item.value" :key="item.value" cols="6">
                        <v-textarea
                          v-model="editedItem[item.value]"
                          :label="item.text"
                          outlined
                          hide-details
                        ></v-textarea>
                      </v-col>
                    </template>

                    <v-col cols="12">
                      <v-text-field v-model="editedItem.id" label="ID" outlined disabled hide-details></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-btn color="primary" text large @click="save">Save</v-btn>
                <v-btn color="primary" text large @click="saveAndGoToNext">Next empty</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="primary" text large @click="close">Cancel</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"> Are you sure you want to delete this item? </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="closeDelete">Cancel</v-btn>
                <v-btn color="primary" text @click="deleteItemConfirm">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon v-if="isDeveloper" class="mr-2" color="primary" small @click="deleteItem(item)"> mdi-delete </v-icon>

        <v-icon color="primary" small @click="editItem(item)">mdi-pencil</v-icon>
      </template>

      <template v-slot:item.author="{ item }">
        {{ item.author || 'Default' }}
      </template>

      <template v-slot:item.updatedAt="{ item }">
        {{ getFormattedDate(item.date) }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { sortHeaderColumnsByPreferred } from '@/services/headerService';

export default {
  name: 'Translations',
  data() {
    return {
      search: '',
      loaded: false,
      dialog: false,
      dialogDelete: false,
      editedIndex: -1,
      editedItem: {
        id: '',
        key: '',
        cs: '',
        en: '',
        de: '',
        hu: '',
        sk: '',
        pl: '',
        ru: '',
        vi: '',
        type: '',
      },
      defaultItem: {
        id: '',
        key: '',
        cs: '',
        en: '',
        de: '',
        hu: '',
        sk: '',
        pl: '',
        ru: '',
        vi: '',
        type: '',
      },
      keyErrorOccurred: false,
      typeErrorOccurred: false,
    };
  },
  computed: {
    ...mapGetters({
      isDeveloper: 'user/isDeveloper',
      isTransKeyExist: 'translations/isTransKeyExist',
      translationsRows: 'translations/rows',
      preferredColumn: 'user/preferredColumn',
      projects: 'projects/list',
      projectHeaders: 'projects/headers',
    }),
    isLoading() {
      return !this.loaded;
    },
    formTitle() {
      return `${-1 === this.editedIndex ? 'New' : 'Edit'} Item`;
    },
    projectCode() {
      return this.$route?.params?.project || 'app';
    },
    subprojectCode() {
      return this.$route.params?.subproject || 'default';
    },
    projectPath() {
      return `${this.projectCode}/${this.subprojectCode}`;
    },
    headers() {
      return [
        { text: 'Actions', value: 'actions', filterable: false, sortable: false },
        {
          align: 'start',
          filterable: true,
          text: 'ID',
          value: 'id',
          visible: true,
          visibleInExport: true,
          sortable: true,
        },
      ].concat(this.sortedProjectHeaderColumns);
    },
    projectHeaderColumns() {
      return this.projectHeaders(this.projectCode);
    },
    sortedProjectHeaderColumns() {
      return [
        ...sortHeaderColumnsByPreferred(this.projectHeaderColumns, this.preferredColumn),
        { text: 'updatedAt', value: 'updatedAt', filterable: false, sortable: true },
        { text: 'User', value: 'author', filterable: false, sortable: true },
      ];
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  async created() {
    this.loaded = false;
    this.editedItem.type = this.projectCode;
    this.defaultItem.type = this.projectCode;

    await this.loadDefaultRows(`${this.projectCode}/default`);
    this.bindTranslationsRows(this.projectPath).finally(() => {
      this.loaded = true;
    });
  },
  methods: {
    ...mapActions({
      loadDefaultRows: 'translations/loadDefaultRows',
      showAlert: 'alert/show',
      bindTranslationsRows: 'translations/bindTranslationsRows',
      addRow: 'translations/addRow',
      updateRow: 'translations/updateRow',
      removeRow: 'translations/removeRow',
      highlightRow: 'translations/highlightRow',
      unhighlightRow: 'translations/unhighlightRow',
    }),
    getFormattedDate(date) {
      if (!date) {
        return '1.1.2021';
      }

      return new Date(date).toLocaleDateString('cs', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      });
    },
    editItem(item) {
      this.editedIndex = this.translationsRows.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    deleteItem(item) {
      this.editedIndex = this.translationsRows.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    async deleteItemConfirm() {
      this.removeRow({ item: Object.assign({}, this.editedItem), projectCode: this.projectPath });
      await this.loadDefaultRows(`${this.projectCode}/default`);
      this.closeDelete();
      this.showAlert({
        message: 'Row deleted successfully.',
        type: 'success',
      });
    },
    close() {
      if (-1 === this.editedIndex) {
        this.dialog = false;
        this.editedItem = Object.assign({}, this.defaultItem);

        return;
      }

      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    async save() {
      if ('' === this.editedItem.key) {
        this.keyErrorOccurred = true;
        this.showAlert({
          message: 'Key cannot be empty',
        });

        return;
      }

      if (-1 < this.editedIndex) {
        try {
          this.updateRow({
            index: this.editedIndex,
            item: Object.assign({}, this.editedItem),
            projectCode: this.projectPath,
          }).then(() => {
            this.showAlert({
              message: 'Row updated successfully.',
              type: 'success',
            });
          });
        } catch (err) {
          this.showAlert({
            message: 'Row not updated successfully.',
            type: 'error',
          });
        }
      } else {
        if (this.isTransKeyExist(this.editedItem.key)) {
          this.keyErrorOccurred = true;
          this.showAlert({
            message: 'Key exists',
          });

          return;
        }

        const maxId =
          this.translationsRows.reduce((max, row) => (row.id > max ? row.id : max), this.translationsRows[0]?.id) || 0;

        try {
          this.addRow({
            item: {
              ...this.editedItem,
              id: parseInt(maxId) + 1,
            },
            projectCode: this.projectPath,
          }).then(() => {
            this.showAlert({
              message: 'Row created successfully.',
              type: 'success',
            });
          });
        } catch (err) {
          this.showAlert({
            message: err || 'Row not created successfully.',
            type: 'error',
          });
        }

        if (this.projectPath === `${this.projectCode}/default`) {
          await this.loadDefaultRows(`${this.projectCode}/default`);
        }
      }

      this.close();
    },
    saveAndGoToNext() {
      this.save();
      this.showAlert({
        message: 'Finding next empty row...',
        type: 'info',
      });

      const nextEmptyRow = this.translationsRows.find((row) => {
        return '' === row[this.preferredColumn];
      });

      if (nextEmptyRow) {
        setTimeout(() => {
          this.editItem(nextEmptyRow);
        }, 1200);
      } else {
        this.showAlert({
          message: 'Nothing found...',
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.style-1 > td {
  color: rgb(215, 215, 44) !important;
}
.style-2 > td {
  color: rgb(4, 114, 67) !important;
}
</style>
