/**
 * Special sort columns for user
 *
 * @param columns
 * @param preferredColumn
 * @returns {*}
 */
export function sortHeaderColumnsByPreferred(columns, preferredColumn) {
  let indexOfCzech = columns.findIndex((column) => 'cs' === column?.value);
  const indexOfPreferred = columns.findIndex((column) => {
    return column?.value === preferredColumn;
  });

  if (-1 !== indexOfPreferred && -1 !== indexOfCzech && preferredColumn !== columns[2]?.value) {
    columns.splice(indexOfCzech + 1, 0, columns.splice(indexOfPreferred, 1)[0]);
  }

  return columns;
}
