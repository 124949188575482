import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';
import 'firebase/storage';
import { firebaseConfig } from '@/config/firebaseConfig';
import { isDevelopment } from '@/config/environment';

firebase.initializeApp(firebaseConfig);

const authentication = firebase.auth();
const db = firebase.database();
const firestore = firebase.firestore();
const storage = firebase.storage();

if (isDevelopment()) {
  // authentication.useEmulator('http://localhost:9099');
  db.useEmulator('localhost', 9000);
  // firestore.useEmulator('localhost', 9090);
}

export { authentication, db, firestore, storage };
