import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import { authentication } from './plugins/firebase';
import JsonViewer from 'vue-json-viewer';
import 'nprogress/nprogress.css';

Vue.use(JsonViewer);

Vue.config.productionTip = false;

let application;
authentication.onAuthStateChanged(async (resp) => {
  if (resp) {
    await store.dispatch('user/fetchUser', resp);
  }

  if (!application) {
    application = new Vue({
      router,
      store,
      vuetify,
      render: (h) => h(App),
    }).$mount('#app');
  }
});
