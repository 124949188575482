import router from '@/router';
import { privileges } from '@/config/security';
import { authentication, firestore } from '@/plugins/firebase';

export const namespaced = true;

export const state = {
  user: null,
};

export const mutations = {
  fetchUser(state, payload) {
    state.user = payload;
  },
  destroyUser(state) {
    state.user = null;
  },
  setUserDarkTheme(state, useDarkTheme) {
    state.user.useDarkTheme = useDarkTheme;
  },
};

export const actions = {
  async fetchUser(context, user) {
    return firestore
      .collection('users')
      .doc(user.email)
      .get()
      .then((userProfile) => {
        context.commit('fetchUser', { uid: userProfile.id, ...userProfile.data() });
      })
      .catch((err) => {
        throw err;
      });
  },
  login: (context, credentials) => {
    return authentication.signInWithEmailAndPassword(credentials.email, credentials.password).then(
      (resp) => {
        context
          .dispatch('fetchUser', { uid: resp.user.email })
          .then(() => {
            return router.push({ name: 'dashboard' });
          })
          .catch((err) => {
            throw err;
          });
      },
      (err) => {
        throw err;
      },
    );
  },
  logout: async (context) => {
    await router.push({ name: 'login' });

    authentication.signOut().then(() => {
      context.commit('destroyUser');
    });
  },
  setUserDarkTheme: (context, useDarkTheme) => {
    return firestore
      .collection('users')
      .doc(context.state.user.uid)
      .update({
        useDarkTheme,
      })
      .then(() => {
        context.commit('setUserDarkTheme', useDarkTheme);
      })
      .catch((err) => {
        throw err;
      });
  },
};

export const getters = {
  email: (state) => {
    return state.user?.email;
  },
  name: (state) => {
    return state.user?.name;
  },
  preferredColumn: (state) => {
    return state.user?.preferred || 'en';
  },
  role: () => {
    return `${state.user?.role?.charAt(0).toUpperCase()}${state.user?.role?.slice(1)}`;
  },
  useDarkTheme: (state) => {
    return state.user?.useDarkTheme;
  },
  isAdmin: (state) => {
    return privileges.adminPrivileges.includes(state.user?.role);
  },
  isManager: (state) => {
    return privileges.managerPrivileges.includes(state.user?.role);
  },
  isDeveloper: (state) => {
    return privileges.developerPrivileges.includes(state.user?.role);
  },
  isEditor: (state) => {
    return privileges.editorPrivileges.includes(state.user?.role);
  },
  isGuest: (state) => {
    return privileges.guestPrivileges.includes(state.user?.role);
  },
  subprojects: (state) => {
    return state.user?.subprojects || [];
  },
};
