export const namespaced = true;

export const state = {
  items: {},
};

export const mutations = {
  addItem(state, payload) {
    state.items[payload.key] = Object.assign({}, payload);
  },
  reset(state) {
    state.items = {};
  },
};

export const actions = {
  addItem: (context, payload) => {
    context.commit('addItem', payload);
  },
  reset: (context) => {
    context.commit('reset');
  },
};

export const getters = {
  items: (state) => {
    return state.items;
  },
};
