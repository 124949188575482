import store from '@/store';
import clean from 'lodash-clean';
import { db } from '@/plugins/firebase';
import { saveAs } from 'file-saver';

export const downloadExportFile = async ({ projectCode }) => {
  const columns = store.getters['projects/projectExportColumns'](projectCode);
  const subprojects = store.getters['projects/projectExportSubprojects'](projectCode);
  const languages = {};
  const exportOutput = {};

  for (let column of columns) {
    languages[column] = {};
  }

  let defaultRows = [];

  await db.ref(`translations/${projectCode}/default`).once('value', function (snapshot) {
    snapshot.forEach(function (childSnapshot) {
      defaultRows.push(childSnapshot.val());
    });
  });

  return db.ref(`translations/${projectCode}`).once('value', function (snapshot) {
    let solutions = {};

    snapshot.forEach(function (childSnapshot) {
      solutions[`${childSnapshot.key}`] = childSnapshot.val();
    });

    for (let solution of Object.keys(solutions)) {
      let solutionRows = [];

      for (let solutionRow of Object.keys(solutions[`${solution}`])) {
        solutionRows.push(
          Object.assign(
            {},
            defaultRows?.find((i) => i.key === solutions[`${solution}`][`${solutionRow}`].key),
            solutions[`${solution}`][`${solutionRow}`],
          ),
        );
      }

      solutions[`${solution}`] = solutionRows;
    }

    for (let subproject of subprojects) {
      if (!solutions[`${subproject.key}`]) {
        let rows = [];

        for (let item of defaultRows) {
          rows.push(Object.assign({}, item));
        }

        solutions[`${subproject.key}`] = rows;
      }
    }

    const projects = Object.keys(solutions);

    for (let solution of projects) {
      let rows = [];

      for (let item of defaultRows) {
        rows.push(
          Object.assign(
            {},
            item,
            solutions[`${solution}`]?.find((i) => i.key === item.key),
          ),
        );
      }

      solutions[`${solution}`] = rows;
    }

    for (let solution of projects) {
      exportOutput[`${solution}`] = languages;
    }

    for (let solution of projects) {
      const solutionData = {};

      for (let language in languages) {
        const languageData = {};

        for (let row of solutions[solution]) {
          if ('' !== row[language]) {
            languageData[row['key']] = `${row[language] || ''}`;
          }
        }

        solutionData[language] = Object.assign({}, languageData);
      }

      exportOutput[solution] = Object.assign({}, solutionData);
    }

    exportOutput['signi'] = Object.assign({}, exportOutput['default']);

    delete exportOutput['default'];

    const fileName = `export-${projectCode}.json`;
    const fileToSave = new Blob([JSON.stringify(clean(exportOutput), null, 2)], {
      type: 'application/json',
      name: fileName,
    });

    saveAs(fileToSave, fileName);
  });
};
