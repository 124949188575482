import { firestore } from '@/plugins/firebase';

export const namespaced = true;

export const state = {
  list: [],
};

export const mutations = {
  addUser(state, payload) {
    state.list.push(payload);
  },
  updateUser(state, payload) {
    const userIndex = state.list.findIndex((user) => {
      return user.email === payload.email;
    });

    state.list[userIndex] = Object.assign(state.list[userIndex], payload);
  },
  deleteUser(state, payload) {
    const userIndex = state.list.findIndex((user) => {
      return user.email === payload.email;
    });

    state.list.splice(userIndex, 1);
  },
  resetUsersList(state) {
    state.list = [];
  },
};

export const actions = {
  async fetchUsers(context) {
    context.commit('resetUsersList');

    return firestore
      .collection('users')
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          context.commit('addUser', doc.data());
        });
      })
      .catch((err) => {
        throw err;
      });
  },
  async addUser(context, payload) {
    return firestore
      .collection('users')
      .doc(payload.email)
      .set(payload)
      .then(() => {
        context.commit('addUser', payload);
      })
      .catch((err) => {
        throw err;
      });
  },
  async updateUser(context, payload) {
    return firestore
      .collection('users')
      .doc(payload.email)
      .set(payload)
      .then(() => {
        context.commit('updateUser', payload);
      })
      .catch((err) => {
        throw err;
      });
  },
  async deleteUser(context, payload) {
    return firestore
      .collection('users')
      .doc(payload.email)
      .delete()
      .then(() => {
        context.commit('deleteUser', payload);
      })
      .catch((err) => {
        throw err;
      });
  },
};

export const getters = {
  list: (state) => {
    return state.list;
  },
};
