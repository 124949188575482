<template>
  <v-menu v-model="menu" :close-on-content-click="false" rounded="lg" offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" class="px-2" dark text v-bind="attrs" v-on="on">
        <v-icon class="pr-2">mdi-account-circle</v-icon>
        {{ user }}
      </v-btn>
    </template>

    <v-card width="22rem">
      <v-list>
        <v-list-item>
          <v-list-item-avatar>
            <v-icon color="primary" x-large>mdi-account-circle</v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title v-text="user"></v-list-item-title>
            <v-list-item-subtitle v-text="`${role} - ${privileges}`"></v-list-item-subtitle>

            <v-list-item-subtitle class="mt-2">AuthToken: {{ userAccessToken }}</v-list-item-subtitle>
            <v-btn color="primary" class="my-2" outlined small @click="copyAccessTokenId">Copy auth token</v-btn>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-action>
            <v-switch v-model="useDark" color="primary"></v-switch>
          </v-list-item-action>
          <v-list-item-title v-text="`Use dark theme`"></v-list-item-title>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item link @click="goToRoute({ name: 'dashboard' })">
          <v-list-item-icon>
            <v-icon>mdi-home-outline</v-icon>
          </v-list-item-icon>

          <v-list-item-title>Dashboard</v-list-item-title>
        </v-list-item>

        <v-divider v-if="isManager"></v-divider>

        <v-list-item v-if="isManager" link @click="goToRoute({ name: 'projects-settings' })">
          <v-list-item-icon>
            <v-icon>mdi-folder-multiple-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Projects settings</v-list-item-title>
        </v-list-item>

        <v-list-item v-if="isManager" link @click="goToRoute({ name: 'users-settings' })">
          <v-list-item-icon>
            <v-icon>mdi-account-group-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Users settings</v-list-item-title>
        </v-list-item>

        <v-list-item v-if="isManager" link @click="goToRoute({ name: 'reports-logs' })">
          <v-list-item-icon>
            <v-icon>mdi-skull-scan</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Logs</v-list-item-title>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list>
        <v-list-item link>
          <v-list-item-title @click="logout">Logout</v-list-item-title>
          <v-list-item-action @click="logout">
            <v-icon color="error">mdi-logout</v-icon>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { authentication } from '@/plugins/firebase';
import { downloadExportFile } from '@/services/exportService';

export default {
  name: 'AppBarUser',
  props: {
    value: Boolean,
  },
  data() {
    return {
      accessToken: null,
      expandItems: [
        {
          action: 'mdi-ticket',
          active: true,
          items: [{ title: 'List Item' }],
          title: 'Projects',
        },
        {
          action: 'mdi-ticket',
          items: [{ title: 'List Item' }],
          title: 'Settings',
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      user: 'user/name',
      role: 'user/role',
      projects: 'projects/list',
      useDarkTheme: 'user/useDarkTheme',
      isDeveloper: 'user/isDeveloper',
      isManager: 'user/isManager',
    }),
    menu: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    useDark: {
      get() {
        return this.useDarkTheme;
      },
      set(value) {
        this.$vuetify.theme.dark = value;
        this.setUserDarkTheme(value);
      },
    },
    privileges() {
      return this.isDeveloper ? 'Full privileges' : 'Partly privileges';
    },
    userAccessToken() {
      return this.accessToken || '';
    },
  },
  watch: {
    useDark: {
      handler: function (value) {
        this.$vuetify.theme.dark = value;
      },
    },
  },
  async mounted() {
    this.$vuetify.theme.dark = this.useDarkTheme;
    this.accessToken = await authentication.currentUser.getIdToken();
  },
  methods: {
    ...mapActions({
      logout: 'user/logout',
      setUserDarkTheme: 'user/setUserDarkTheme',
    }),
    exportTranslations(projectCode) {
      this.menu = false;
      downloadExportFile({ projectCode });
    },
    copyAccessTokenId() {
      navigator.clipboard.writeText(this.accessToken).then(() => {
        this.menu = false;
        alert('Copied to clipboard');
      });
    },
    goToRoute(route) {
      this.menu = false;

      if (route.name === this.$route.name && 'translations' !== route.name) {
        return;
      }

      if ('translations' === route.name && route?.params?.type === this.$route?.params?.type) {
        return;
      }

      this.$router.push(route);
    },
  },
};
</script>

<style lang="scss" scoped></style>
