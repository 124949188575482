import { db, firestore } from '@/plugins/firebase';

export const namespaced = true;

export const state = {
  list: [],
  stats: {},
};

export const mutations = {
  addProject(state, payload) {
    state.list.push(payload);
  },
  updateProject(state, payload) {
    const projectIndex = state.list.findIndex((project) => {
      return project.code === payload.code;
    });

    state.list[projectIndex] = Object.assign(state.list[projectIndex], payload);
  },
  deleteProject(state, payload) {
    const projectIndex = state.list.findIndex((project) => {
      return project.code === payload.code;
    });

    state.list.splice(projectIndex, 1);
  },
  resetProjectsList(state) {
    state.list = [];
    state.stats = {};
  },
  updateStats(state, payload) {
    state.stats[payload.projectCode] = {
      ...state.stats[payload.projectCode],
      count: payload.count,
    };
  },
};

export const actions = {
  async fetchProjects(context) {
    context.commit('resetProjectsList');

    return firestore
      .collection('projects')
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          context.commit('addProject', doc.data());
          context.dispatch('updateStats', doc.data().code);
        });
      })
      .catch((err) => {
        throw err;
      });
  },
  async addProject(context, payload) {
    return firestore
      .collection('projects')
      .doc(payload.code)
      .set(payload)
      .then(() => {
        context.commit('addProject', payload);
      })
      .catch((err) => {
        throw err;
      });
  },
  async updateProject(context, payload) {
    return firestore
      .collection('projects')
      .doc(payload.code)
      .set(payload)
      .then(() => {
        context.commit('updateProject', payload);
      })
      .catch((err) => {
        throw err;
      });
  },
  async deleteProject(context, payload) {
    return firestore
      .collection('projects')
      .doc(payload.code)
      .delete()
      .then(() => {
        context.commit('deleteProject', payload);
      })
      .catch((err) => {
        throw err;
      });
  },
  async updateStats(context, projectCode) {
    await db
      .ref(`translations/${projectCode}`)
      .once('value')
      .then(function (snapshot) {
        context.commit('updateStats', {
          projectCode,
          count: snapshot.numChildren(),
        });
      });
  },
};

export const getters = {
  list: (state) => {
    return state?.list;
  },
  projectExportColumns: (state) => (projectCode) => {
    const currentProject = state.list.find((project) => {
      return project.code === projectCode;
    });

    return currentProject?.columns
      .filter((column) => {
        return column?.visibleInExport && 'key' !== column.value;
      })
      ?.map((column) => column?.value);
  },
  projectExportSubprojects: (state) => (projectCode) => {
    return state.list.find((project) => project.code === projectCode)?.subprojects || [];
  },
  projectRowsCount: (state) => (projectCode) => {
    return state.stats[projectCode]?.count;
  },
  headers: (state) => (projectCode) => {
    return (
      state.list.find((project) => {
        return project.code === projectCode;
      })?.columns || []
    );
  },
};
