import Vue from 'vue';
import Vuex from 'vuex';
import { vuexfireMutations } from 'vuexfire';
import * as alert from './modules/alert';
import * as editing from './modules/editing';
import * as projects from './modules/projects';
import * as translations from './modules/translations';
import * as user from './modules/user';
import * as users from './modules/users';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    alert,
    editing,
    projects,
    translations,
    user,
    users,
  },
  state: {},
  mutations: {
    ...vuexfireMutations,
  },
  actions: {},
  getters: {},
});
