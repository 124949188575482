export const namespaced = true;

export const state = {
  message: '',
  show: false,
  timeout: 3000,
  type: 'error',
};

export const mutations = {
  set(state, payload) {
    state.message = payload?.message || 'Error occurred';
    state.show = true;
    state.timeout = payload?.timeout || 3000;
    state.type = payload?.type || 'error';
  },
  reset(state) {
    state.message = '';
    state.timeout = 3000;
    state.show = false;
  },
};

export const actions = {
  show: (context, payload) => {
    context.commit('set', payload);
  },
  reset: (context) => {
    context.commit('reset');
  },
};

export const getters = {
  message: (state) => {
    return state.message;
  },
  show: (state) => {
    return state.show;
  },
  timeout: (state) => {
    return state.timeout;
  },
  type: (state) => {
    return state.type;
  },
};
