/**
 * Project roles definition
 */
const adminPrivileges = ['admin'];
const managerPrivileges = [...adminPrivileges, 'manager'];
const developerPrivileges = [...managerPrivileges, 'developer'];
const editorPrivileges = [...developerPrivileges, 'editor'];
const guestPrivileges = [...editorPrivileges, 'guest'];

/**
 * Authenticated user roles
 *
 * @type {{
 *  guestPrivileges: string[],
 *  editorPrivileges: string[],
 *  adminPrivileges: string[],
 *  developerPrivileges: string[],
 *  managerPrivileges: string[]
 * }}
 */
export const privileges = {
  adminPrivileges,
  managerPrivileges,
  developerPrivileges,
  editorPrivileges,
  guestPrivileges,
};
