<template>
  <v-app-bar dense app>
    <div class="d-flex align-center">
      <v-img
        alt="Signi Logo"
        class="shrink mr-2"
        contain
        src="https://app.signi.com/images/logo-signi_com.png"
        transition="scale-transition"
        width="150"
        style="cursor: pointer"
        @click="goToDashboard"
      />
    </div>

    <v-spacer></v-spacer>

    <AppBarUser v-model="menu" />
  </v-app-bar>
</template>

<script>
import AppBarUser from './AppBarUser';

export default {
  name: 'AppBar',
  components: { AppBarUser },
  data() {
    return {
      menu: false,
    };
  },
  methods: {
    goToDashboard() {
      if ('dashboard' === this.$route.name) {
        return;
      }

      this.$router.push({ name: 'dashboard' });
    },
  },
};
</script>

<style lang="scss" scoped></style>
