const urlsBuilder = () => {
  const entryPoint = process.env['VUE_APP_FRONTEND_URL'];

  if ('undefined' !== typeof entryPoint) {
    return entryPoint;
  }

  return window.location.origin;
};

export const isDevelopment = () => {
  const appUrl = urlsBuilder();

  return 'development' === process.env.NODE_ENV || appUrl.includes('localhost');
};
