import { db } from '@/plugins/firebase';
import { firebaseAction } from 'vuexfire';
import Logger from '@/plugins/Logger';

export const namespaced = true;

export const state = {
  defaultRows: [],
  rows: [],
};

export const mutations = {
  ADD_DEFAULTS_ROWS(state, payload) {
    state.defaultRows = payload;
  },
};

export const actions = {
  loadDefaultRows: (context, projectCode) => {
    return db.ref(`translations/${projectCode}`).once('value', function (snapshot) {
      let rows = [];

      snapshot.forEach(function (childSnapshot) {
        rows.push(Object.assign({}, childSnapshot.val(), { fbKey: childSnapshot.key }));
      });

      context.commit('ADD_DEFAULTS_ROWS', rows);
    });
  },
  bindTranslationsRows: firebaseAction(async (context, projectCode) => {
    await context.unbindFirebaseRef('rows');

    return context.bindFirebaseRef('rows', db.ref(`translations/${projectCode}`));
  }),
  addRow: firebaseAction((context, payload) => {
    if (context.getters['isTransKeyExist'](payload.item.key)) {
      throw new Error('Key already exists');
    }

    const newRecord = Object.assign({}, payload.item, {
      author: context.rootGetters['user/email'],
      createdAt: `${new Date()}`,
      updatedAt: `${new Date()}`,
    });

    Logger.logRecordChange(
      Object.assign({}, { data: newRecord, dataBefore: {}, description: 'New record', project: payload.projectCode }),
    ).then(() => {});

    return db.ref(`translations/${payload.projectCode}`).push(newRecord);
  }),
  updateRow: firebaseAction((context, payload) => {
    const rootItem = context.getters['rootRow'](payload.item.key);
    let updatedRow = {};

    for (let property of Object.keys(payload.item)) {
      if (payload.item[`${property}`] !== rootItem[`${property}`]) {
        updatedRow[`${property}`] = payload.item[`${property}`];
      }
    }

    updatedRow.id = rootItem.id;
    updatedRow.key = rootItem.key;

    const updatedRecord = Object.assign(updatedRow, {
      createdAt: updatedRow?.createdAt || `${new Date()}`,
      updatedAt: `${new Date()}`,
      author: context.rootGetters['user/email'],
    });

    Logger.logRecordChange(
      Object.assign(
        {},
        { data: updatedRecord, dataBefore: rootItem, description: 'Update record', project: payload.projectCode },
      ),
    ).then(() => {});

    return db.ref(`translations/${payload.projectCode}/${rootItem['fbKey']}`).update(updatedRecord);
  }),
  removeRow: firebaseAction((context, payload) => {
    const item = context.getters['currentRow'](payload.item.key);

    return db.ref(`translations/${payload.projectCode}/${item['.key']}`).remove();
  }),
  highlightRow: (context, payload) => {
    return context.dispatch('updateRow', {
      projectCode: payload.projectCode,
      item: {
        key: payload.item.key,
        highlight: true,
      },
    });
  },
  unhighlightRow: (context, payload) => {
    return context.dispatch('updateRow', {
      projectCode: payload.projectCode,
      item: {
        key: payload.item.key,
        highlight: false,
      },
    });
  },
};

export const getters = {
  currentRow: (state) => (key) => {
    return state.rows.find((row) => {
      return row.key === key;
    });
  },
  rootRow: (state) => (key) => {
    return state.defaultRows.find((row) => {
      return row.key === key;
    });
  },
  isTransKeyExist: (state) => (key) => {
    return (
      'undefined' !==
      typeof state.rows.find((transRow) => {
        return transRow?.key === key;
      })
    );
  },
  rows: (state) => {
    let rows = [];

    for (let item of state.defaultRows) {
      rows.push(
        Object.assign(
          {},
          item,
          state.rows?.find((i) => i.key === item.key),
        ),
      );
    }

    return rows;
  },
  rowsByType: (state) => (type) => {
    return state.rows.filter((r) => r.type === type);
  },
};
