<template>
  <v-card width="100%" max-width="500px" class="mx-auto" elevation="12" rounded="lg">
    <v-card-title class="justify-center px-5 py-8">
      <div class="d-flex align-center">
        <v-img
          alt="Signi Logo"
          class="shrink mr-2"
          contain
          src="https://app.signi.com/images/logo-signi_com.png"
          transition="scale-transition"
          width="180"
        />
      </div>
    </v-card-title>

    <v-divider></v-divider>

    <v-card-text class="px-10">
      <v-form>
        <v-text-field
          v-model="email"
          type="text"
          name="email"
          label="E-mail"
          prepend-icon="mdi-account-circle"
          :error="error"
          @keydown.enter="userLogin"
        />

        <v-text-field
          v-model="password"
          :type="showPassword ? 'text' : 'password'"
          name="password"
          label="Password"
          prepend-icon="mdi-lock"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :error="error"
          @click:append="showPassword = !showPassword"
          @keydown.enter="userLogin"
        />
      </v-form>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions class="justify-center px-5 py-5">
      <v-btn color="primary" min-width="12rem" :loading="clickButton" @click="userLogin"> Login </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'Login',
  data() {
    return {
      email: '',
      password: '',
      error: false,
      showPassword: false,
      clickButton: false,
      snackbar: false,
      snackbarMessage: '',
    };
  },
  computed: {
    credentials() {
      return {
        email: this.email,
        password: this.password,
      };
    },
  },
  methods: {
    ...mapActions({
      login: 'user/login',
      showAlert: 'alert/show',
    }),
    userLogin() {
      this.clickButton = true;
      this.login(this.credentials)
        .then(() => {
          this.error = false;
        })
        .catch((err) => {
          this.showAlert({
            message: err.message || 'Bad credentials!',
          });
          this.error = true;
        })
        .finally(() => {
          this.clickButton = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
